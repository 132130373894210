import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"

const EpisodeContext = React.createContext()

export function EpisodeProvider(props) {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        nodes {
          id
          html
          frontmatter {
            id
            title
            summary
            number
            resources
            enclosure_url
            guestSummary
            guestName
          }
          fields {
            slug
          }
        }
      }
    }
  `)
  const [currentPlaying, setCurrentPlaying] = React.useState(
    data.allMarkdownRemark.nodes[0]
  )
  const [playerState, setPlayerState] = React.useState("pause")

  return (
    <EpisodeContext.Provider
      value={{
        state: currentPlaying,
        setCurrentPlaying,
        playerState,
        setPlayerState,
      }}
      {...props}
    />
  )
}

export class EpisodeConsumer extends Component {
  render() {
    return (
      <EpisodeContext.Consumer>{this.props.children}</EpisodeContext.Consumer>
    )
  }
}
