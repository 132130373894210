// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-episode-js": () => import("/opt/build/repo/src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credits-js": () => import("/opt/build/repo/src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

