export default {
  podcastSeason: "1",
  headerImageHeight: [300, 400],
  spotifyUrl:
    "https://open.spotify.com/show/0fkBMBsvEq646LtbBECHGx?si=zbTc5eqoRKqbRdZC2ZIJJw",
  applePodcastsUrl:
    "https://podcasts.apple.com/it/podcast/empty-the-cache/id1482562705",
  googlePodcastsUrl: "https://podcasts.google.com/?feed=aHR0cHM6Ly93d3cuZW1wdHl0aGVjYWNoZS5jb20vcG9kY2FzdC54bWw",
  pocketcasts: "https://pca.st/ao3yicqi",
}
